import {
  lazy,
  Suspense,
} from 'react';
import {
  Navigate,
  useRoutes,
} from "react-router-dom";

import { authRoutes } from './auth';
import { mainRoutes } from "./main";
import { adminRoutes } from './admin';
import { dashboardRoutes } from "./dashboard";

const HomePage = lazy(() => import('src/pages/home'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <HomePage />
        </Suspense>
      ),
    },
    ...authRoutes,
    ...mainRoutes,
    ...adminRoutes,
    ...dashboardRoutes,

    {path: '*', element: <Navigate to="/404" replace />},
  ]);
}
