import { useState, useRef } from "react";
import { Box, Link, Menu, Button, MenuItem, IconButton } from "@mui/material";

import { styled } from "@mui/material/styles";

import { paths } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { useRouter } from "src/routes/hooks";
import { useAuthContext } from "src/auth/hooks";
import { RouterLink } from "src/routes/components";

export default function HeaderMenu() {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const router = useRouter();
  const { authenticated, user, logout } = useAuthContext();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const handleLogout = () => {
    logout();
    handleToggle();
  };

  const handleSignup = () => {
    router.push(paths.auth.signUp);
    handleToggle();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-haspopup="true"
        id="header-menu-button"
        onClick={handleToggle}
        sx={{ padding: 0, marginLeft: "1rem" }}
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "header-menu" : undefined}
      >
        <Iconify icon="heroicons-solid:menu-alt-3" />
      </IconButton>

      <Menu
        open={open}
        id="header-menu"
        onClose={handleClose}
        anchorEl={anchorRef.current}
        aria-labelledby="header-menu-button"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& .MuiMenu-list": {
            paddingY: 0,
          },
          "& .MuiMenu-paper": {
            padding: "24px 30px 10px 30px",
            backgroundColor: "#F7F7F7",
          },
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "auto",
          },
        }}
        disableScrollLock={true}
        onKeyDown={handleListKeyDown}
      >
        {authenticated ? null : (
          <Box
            sx={{
              display: "flex",
              marginBottom: "35px",
              justifyContent: "center",
            }}
          >
            <Button fullWidth variant="outlined" onClick={handleSignup}>
              Join Now
            </Button>
          </Box>
        )}
        {authenticated ? (
          <StyledMenuItem>
            <StyledLink
              component={RouterLink}
              to={user?.role ? paths.admin.dashboard : paths.dashboard.root}
              onClick={handleToggle}
            >
              Dashboard
            </StyledLink>
          </StyledMenuItem>
        ) : (
          <StyledMenuItem>
            <StyledLink
              component={RouterLink}
              to={paths.jobList}
              onClick={handleToggle}
            >
              Job Listings
            </StyledLink>
          </StyledMenuItem>
        )}
        {/* <StyledMenuItem>
          <StyledLink
            component={RouterLink}
            to={paths.about}
            onClick={handleToggle}
          >
            About
          </StyledLink>
        </StyledMenuItem> */}
        <StyledMenuItem>
          <StyledLink
            component={RouterLink}
            to={paths.howItWorks}
            onClick={handleToggle}
          >
            How It Works
          </StyledLink>
        </StyledMenuItem>
        <StyledMenuItem>
          <StyledLink
            component={RouterLink}
            to={paths.guidelines}
            onClick={handleToggle}
          >
            Community Guidelines
          </StyledLink>
        </StyledMenuItem>
        {authenticated ? (
          <Box
            sx={{
              display: "flex",
              marginTop: "35px",
              justifyContent: "center",
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              sx={{
                color: "red",
                border: "1px solid red",
              }}
              onClick={handleLogout}
            >
              Log out
            </Button>
          </Box>
        ) : null}
      </Menu>
    </>
  );
}

const StyledMenuItem = styled(MenuItem)(() => ({
  width: "15rem",
  marginBottom: "0.675rem",
  padding: "0 0 0.675rem 0",
  borderBottom: "1px solid #ebebeb",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  width: "100%",
  textAlign: "left",
  fontSize: "0.875rem",
  fontFamily: "Open sans",
  color: theme.palette.common.black,
}));
