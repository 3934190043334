import { useEffect } from "react";
import PropTypes from "prop-types";

import { Box, Stack, Drawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { usePathname } from "src/routes/hooks";
import Scrollbar from "src/components/scrollbar";
import { useResponsive } from "src/hooks/use-responsive";
import { NavSectionVertical } from "src/components/nav-section";

import Logo from "src/components/logo";
import { HEADER, NAV } from "../config-layout";
import { useNavData } from "./config-navigation";
import NavToggleButton from "../common/nav-toggle-button";

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {
  const theme = useTheme();

  const pathname = usePathname();

  const lgUp = useResponsive("up", "lg");

  const navData = useNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line
  }, [pathname, onCloseNav]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          pt: 1,
          height: HEADER.H_DESKTOP,
        }}
      >
        <Logo
          variant={lgUp ? "white" : "primary"}
          sx={{ mt: 3, ml: 4, mb: 1, width: "13rem" }}
        />
      </Box>

      <NavSectionVertical
        data={navData}
        slotProps={{
          color: "white",
          gap: 16,
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavUpgrade /> */}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.W_VERTICAL,
            // borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};
