import { Box } from "@mui/material";

import Header from "../common/header";
import Footer from "../common/footer";
import { HEADER } from "../config-layout";

export default function CompactLayout({ children }) {
  return (
    <>
      <Header />
      <Box
        component="main"
        paddingTop={{
          xs: `${HEADER.H_MOBILE + 20}px`,
          md: `${HEADER.H_DESKTOP + 20}px`,
        }}
      >
        {children}
      </Box>
      <Footer />
    </>
  );
}
