import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Iconify from "src/components/iconify";
import { useResponsive } from "src/hooks/use-responsive";
import { useSettingsContext } from "src/components/settings";

import { NAV } from "../config-layout";

export default function NavToggleButton({ sx, ...other }) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const lgUp = useResponsive("up", "lg");

  if (!lgUp) {
    return null;
  }

  return (
    <IconButton
      size="small"
      onClick={() =>
        settings.onUpdate(
          "themeLayout",
          settings.themeLayout === "vertical" ? "mini" : "vertical"
        )
      }
      sx={{
        p: 0.5,
        top: 36,
        position: "fixed",
        left: NAV.W_VERTICAL - 13,
        zIndex: theme.zIndex.appBar + 1,
        border: `dashed 1px ${theme.palette.divider}`,
        background: theme.palette.common.white,
        "&:hover": {
          background: theme.palette.common.white,
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={
          settings.themeLayout === "vertical"
            ? "eva:arrow-ios-back-fill"
            : "eva:arrow-ios-forward-fill"
        }
      />
    </IconButton>
  );
}
