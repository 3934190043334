import { useTheme } from "@mui/material/styles";
import { Stack, AppBar, Toolbar, IconButton, Typography } from "@mui/material";

import Iconify from "src/components/iconify";
import { usePathname } from "src/routes/hooks";
import { useResponsive } from "src/hooks/use-responsive";
import { useSettingsContext } from "src/components/settings";

import { HEADER, NAV } from "../config-layout";
import { useNavData } from "./config-navigation";
import AccountPopover from "../common/account-popover";

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavMini = settings.themeLayout === "mini";

  const lgUp = useResponsive("up", "lg");

  const pathname = usePathname();

  const { items: navItems } = useNavData()[0];

  let activePage = {};

  if (pathname.includes("/user-details/")) {
    activePage = navItems.find((item) => item.path === "/admin/dashboard");
  } else {
    activePage = navItems.find((item) => item.path === pathname);
  }

  const renderContent = (
    <>
      <Stack direction="row" gap={1}>
        {!lgUp && (
          <IconButton onClick={onOpenNav}>
            <Iconify icon="icon-park-outline:menu-fold-one" />
          </IconButton>
        )}

        <Stack direction="row" gap="1rem" alignItems="center">
          {activePage.icon}
          <Typography
            component="h1"
            fontWeight="bold"
            fontSize="1.375rem"
            textTransform="capitalize"
            color={theme.palette.primary.main}
          >
            {activePage.title}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1 }}
        >
          {/* <MessagesPopover /> */}
          <AccountPopover />
        </Stack>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        ...(lgUp && {
          height: HEADER.H_DESKTOP,
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
        }),
        ...(isNavMini && {
          width: `calc(100% - ${NAV.W_MINI + 1}px)`,
        }),
        ...(!lgUp &&
          isNavMini && {
            width: `100%`,
          }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          gap: "3rem",
          justifyContent: "space-between",
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
