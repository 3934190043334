import axios from "axios";
const getToken = () => {
  return window.localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;
};

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_URL,
  timeout: 15000,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

http.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});
http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    if (err.code === "ECONNABORTED") {
      throw err;
    }
    if (err.code === "ERR_CANCELED") {
      throw err;
    }
    if (
      err.response.status === 401 ||
      err.response.status === 403 ||
      err.response.status === 406
    ) {
      window.localStorage.clear();
      window.location.href = "/auth/login";
    }
    return Promise.reject(err);
  }
);

export default http;
