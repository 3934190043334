import { useTheme } from "@mui/material/styles";
import { useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { useParams } from "src/routes/hooks/use-params";

export default function PageLoader() {
  const { params } = useParams();
  const ref = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (!!ref.current) {
      ref.current.continuousStart();
    }
  }, [params]);

  return (
    <LoadingBar height="3px" color={theme.palette.primary.main} ref={ref} />
  );
}
