import { m } from "framer-motion";

import {
  Box,
  Avatar,
  Divider,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

import { useRouter } from "src/routes/hooks";
import { useAuthContext } from "src/auth/hooks";

import { varHover } from "src/components/animate";
import { useJobsContext } from "src/context/jobs/use-jobs-context";
import CustomPopover, { usePopover } from "src/components/custom-popover";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { user, logout } = useAuthContext();
  const { initializeJobs } = useJobsContext();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      initializeJobs();
      popover.onClose();
      router.replace("/");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {user?.fullName?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem
          onClick={() => {
            router.push("/dashboard");
            popover.onClose();
          }}
          sx={{ fontWeight: "fontWeightBold" }}
        >
          My Dashboard
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          sx={{ fontWeight: "fontWeightBold", color: "error.main" }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
