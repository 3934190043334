import merge from "lodash/merge";

import { link } from "./components/link";
import { table } from "./components/table";
import { appBar } from "./components/appbar";
import { button } from "./components/button";
import { defaultProps } from "./default-props";

export function componentsOverrides(theme) {
  const components = merge(
    link(theme),
    table(theme),
    appBar(theme),
    button(theme),
    defaultProps(theme)
  );

  return components;
}
