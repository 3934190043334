const ROOTS = {
  BASE: "/",
  AUTH: "/auth",
  ADMIN: "/admin",
  DASHBOARD: "/dashboard",
};

export const paths = {
  landing: ROOTS.BASE,

  faq: `/faq`,
  tos: `/tos`,
  blogs: `/blogs`,
  // about: `/about`,
  jobList: `/job-list`,
  contactUs: `/contact-us`,
  howItWorks: `/how-it-works`,
  repoleved: `/repoleved-mur`,
  jobDetail: `/job-detail/:id`,
  privacyPolicy: `/privacy-policy`,
  guidelines: `/community-guidelines`,

  auth: {
    login: `${ROOTS.AUTH}/login`,
    signUp: `${ROOTS.AUTH}/sign-up`,
    verifyEmail: `${ROOTS.AUTH}/verify-email`,
    createProfile: `${ROOTS.AUTH}/create-profile`,
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
    myProfile: `${ROOTS.DASHBOARD}/profile`,
    savedJobs: `${ROOTS.DASHBOARD}/saved-jobs`,
    jobDetail: `${ROOTS.DASHBOARD}/job-detail/:id`,
    savedJobDetail: `${ROOTS.DASHBOARD}/saved-jobs/:id`,
  },
  admin: {
    root: ROOTS.ADMIN,
    dashboard: `${ROOTS.ADMIN}/dashboard`,
    userDetails: `${ROOTS.ADMIN}/dashboard/user-details/:id`,
  },
};
