import {
  lazy,
  Suspense,
} from 'react';
import { Outlet } from 'react-router-dom';

import AdminLayout from 'src/layouts/admin';
import { RoleBasedGuard } from 'src/auth/guard';

import { paths } from '../paths';
import PageLoader from 'src/layouts/common/page-loader';

const DashboardPage = lazy(() => import('src/pages/admin'));
const UserDetailsPage = lazy(() => import('src/pages/admin/user-details'));

export const adminRoutes = [
  {
    element: (
      <RoleBasedGuard hasContent roles={[1, 2]}>
        <Suspense fallback={<PageLoader />}>
          <AdminLayout>
            <Outlet />
          </AdminLayout>
        </Suspense>
      </RoleBasedGuard>
    ),
    children: [
      { path: paths.admin.dashboard, element: <DashboardPage /> },
      { path: paths.admin.userDetails, element: <UserDetailsPage /> },
    ],
  }
];
