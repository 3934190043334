import { useEffect, useCallback } from "react";

import { paths } from "src/routes/paths";
import { useRouter, useSearchParams } from "src/routes/hooks";

import { useAuthContext } from "../hooks";

export default function GuestGuard({ children }) {
  const { loading } = useAuthContext();
  return (
    <>{loading ? <div>Loading...</div> : <Container> {children}</Container>}</>
  );
}

function Container({ children }) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get("returnTo") || paths.dashboard.root;
  const { authenticated } = useAuthContext();
  const check = useCallback(() => {
    if (authenticated) {
      router.replace(returnTo);
    }
  }, [authenticated, returnTo, router]);
  useEffect(() => {
    check();
  }, [check]);
  return <>{children}</>;
}
