import { useMemo, useEffect, useReducer, useCallback, useRef } from "react";

import { ScrollContext } from "./scroll-context";
import { INITIALIZE, SET_SCROLL_TO_REF, SET_SHOULD_SCROLL_TO } from "./types";

const initialState = {
  shouldScrollTo: false,
  scrollToRef: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    return {
      scrollToRef: null,
      shouldScrollTo: false,
    };
  }

  if (action.type === SET_SHOULD_SCROLL_TO) {
    return {
      ...state,
      shouldScrollTo: action.payload.value,
    };
  }

  if (action.type === SET_SCROLL_TO_REF) {
    return {
      ...state,
      scrollToRef: action.payload.value,
    };
  }

  return state;
};

export function ScrollProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const scrollToRef = useRef(null);

  useEffect(() => {
    dispatch({
      type: SET_SCROLL_TO_REF,
      payload: {
        value: scrollToRef,
      },
    });
  }, []);

  const scrollTo = useCallback(() => {
    if (state.scrollToRef?.current && state.shouldScrollTo) {
      state.scrollToRef.current.scrollIntoView({ behavior: "smooth" });
      dispatch({
        type: SET_SHOULD_SCROLL_TO,
        payload: {
          value: false,
        },
      });
    }
  }, [state.scrollToRef, state.shouldScrollTo]);

  useEffect(() => {
    scrollTo();
  }, [state.shouldScrollTo, scrollTo]);

  const setShouldScrollTo = useCallback((value) => {
    dispatch({
      type: SET_SHOULD_SCROLL_TO,
      payload: {
        value,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      scrollToRef: state.scrollToRef,
      shouldScrollTo: state.shouldScrollTo,
      setShouldScrollTo,
    }),
    [state.scrollToRef, state.shouldScrollTo, setShouldScrollTo]
  );

  return (
    <ScrollContext.Provider value={memoizedValue}>
      {children}
    </ScrollContext.Provider>
  );
}
